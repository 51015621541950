import { render, staticRenderFns } from "./ModelCard.vue?vue&type=template&id=2cc92e2e&scoped=true"
import script from "./ModelCard.vue?vue&type=script&setup=true&lang=js"
export * from "./ModelCard.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ModelCard.vue?vue&type=style&index=0&id=2cc92e2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prett_mslvjkx5fqxq5hwkswdqi36zjm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cc92e2e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConfirmationDialog: require('/vercel/path0/components/ConfirmationDialog.vue').default,UnauthorizedIcon: require('/vercel/path0/components/UnauthorizedIcon.vue').default})
